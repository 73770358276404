import { Component, Renderer2, OnInit, OnDestroy } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AuthService, CommonService, InternationalizationService } from './services';
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject, Subscription, timer } from 'rxjs';
import localeFr from '@angular/common/locales/fr';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { build_Configurations, dataGrid } from '../environments/environment';

import '@cds/core/toggle/register.js';
import * as moment from 'moment-timezone';

import "@cds/core/icon/register.js";
import { checkIcon, ClarityIcons, cogIcon, coinBagIcon, connectIcon, copyIcon, detailsIcon, exportIcon, folderOpenIcon, importIcon, infoCircleIcon, logoutIcon, noAccessIcon, pencilIcon, plusIcon, popOutIcon, refreshIcon, searchIcon, storeIcon, successStandardIcon, timesIcon, usersIcon } from '@cds/core/icon';
import { IconShapeTuple } from '@cds/core/icon/interfaces/icon.interfaces';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit, OnDestroy {
  loadValue = 15;
  loadDisplay = true;
  pageSettings;

  private readonly _destroying$ = new Subject<void>();

  isRoleLoaded: boolean;
  subscription !: Subscription;
  expDate: number;
  actualDate: any;
  currentDate: any;

  shapes: IconShapeTuple[] = [];

  showRegionBanner: boolean;

  constructor(
    private readonly authSvc: AuthService,
    private readonly i18Svc: InternationalizationService,
    private readonly renderer: Renderer2,
    private readonly router: Router,
    private readonly config: NgbModalConfig,
    private readonly msalBroadcastService: MsalBroadcastService,
    private readonly msalService: MsalService,
    private readonly common: CommonService
  ) {
    this.config.backdrop = 'static';
    this.config.keyboard = false;
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationStart) {
        this.clearSettings();
        if (e.url !== '/') {
          this.loadDisplay = true;
          this.loadValue = 50;
        }
      }
      if (e instanceof NavigationEnd) {
        if (e.url !== '/') {
          setTimeout(function () {
            this.loadValue = 100;
            this.loadDisplay = false;
          }, 300);
        } 
        this.showRegionBanner = e.url === '/dashboard' || e.url === '';
      }
    });
    this.i18Svc.initialize();

    this.shapes = [
      searchIcon,
      cogIcon,
      logoutIcon,
      refreshIcon,
      pencilIcon,
      exportIcon,
      folderOpenIcon,
      successStandardIcon,
      noAccessIcon,
      pencilIcon,
      plusIcon,
      checkIcon,
      timesIcon,
      copyIcon,
      storeIcon,
      importIcon,
      coinBagIcon,
      detailsIcon,
      connectIcon,
      usersIcon,
      infoCircleIcon,
      popOutIcon
    ];

    this.shapes.forEach(x => ClarityIcons.addIcons(x));

  }

  
   ngOnInit() {
    //for a complete msal example go to: https://github.com/AzureAD/microsoft-authentication-library-for-js/tree/dev/samples/msal-angular-v2-samples
    // console.log('object', this.msalService.instance.getAllAccounts()[0]['homeAccountId']);
    // console.log('object', (this.msalService.instance.getAllAccounts()));
    // console.log('aut', this.msalService.instance.getAccountByHomeId(this.msalService.instance.getAllAccounts()[0]['homeAccountId']).idTokenClaims.auth_time);
    // console.log('exp', ((this.msalService.instance.getAccountByHomeId(this.msalService.instance.getAllAccounts()[0]['homeAccountId'])).idTokenClaims.exp));
    // console.log('object', this.msalService.instance.getAllAccounts().length > 0);
    registerLocaleData(localeEn, 'en');
    registerLocaleData(localeFr, 'fr');
    registerLocaleData(localeEs, 'es');

     this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        if (!this.isRoleLoaded) {
          this.isRoleLoaded = true;
          this.authSvc.getRoles();
          this.authSvc.GetCurrentUser().subscribe(data => {
            this.authSvc.getLanguage(data?.result?.languageId);
          });
        }
        this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        if (!this.isRoleLoaded) {
          this.isRoleLoaded = true;
          this.authSvc.getRoles();
          this.authSvc.GetCurrentUser().subscribe(data => {
            this.checkTime();
            this.authSvc.getLanguage(data?.result?.languageId);
          });
        }
      })
      });

     


    // page settings
    this.pageSettings = {
      pageSidebarMinified: true,
      pageContentFullHeight: false,
      pageContentFullWidth: false,
      pageContentInverseMode: false,
      pageWithFooter: false,
      pageWithoutSidebar: false,
      pageSidebarRight: false,
      pageSidebarRightCollapsed: false,
      pageSidebarTwo: false,
      pageSidebarWide: false,
      pageSidebarTransparent: false,
      pageSidebarLight: false,
      pageTopMenu: false,
      pageEmpty: false,
      pageBodyWhite: true,
      pageMobileSidebarToggled: false,
      pageMobileSidebarFirstClicked: false,
      pageMobileSidebarRightToggled: false,
      pageMobileSidebarRightFirstClicked: false
    };

    this.setColor();

    this.setFavIcon();
    this.setDatagridPageSize();
  }

  setDatagridPageSize() {
    this.common.setDataGridPageSize(dataGrid.pageSize);
  }

  setColor() {
    const root = document.documentElement;
    const defaultColor = build_Configurations.color || '#0072a3';
    root.style.setProperty('--dbk-primary-color', defaultColor);
    root.style.setProperty('--clr-color-action-600', defaultColor);
    root.style.setProperty('--clr-color-action-800', defaultColor);
    root.style.setProperty('--cds-alias-object-interaction-background-highlight', defaultColor);
    root.style.setProperty('--cds-alias-status-info', defaultColor);
  }

  // clear settings to default
  clearSettings() {
    this.pageSettings.pageSidebarMinified = true;
    this.pageSettings.pageContentFullHeight = false;
    this.pageSettings.pageContentFullWidth = false;
    this.pageSettings.pageWithFooter = false;
    this.pageSettings.pageWithoutSidebar = false;
    this.pageSettings.pageSidebarRight = false;
    this.pageSettings.pageSidebarRightCollapsed = false;
    this.pageSettings.pageSidebarTwo = false;
    this.pageSettings.pageSidebarWide = false;
    this.pageSettings.pageSidebarTransparent = false;
    this.pageSettings.pageSidebarLight = false;
    this.pageSettings.pageTopMenu = false;
    this.pageSettings.pageEmpty = false;
    this.pageSettings.pageBodyWhite = false;
    this.pageSettings.pageContentInverseMode = false;
    this.pageSettings.pageMobileSidebarToggled = false;
    this.pageSettings.pageMobileSidebarFirstClicked = false;
    this.pageSettings.pageMobileRightSidebarToggled = false;
    this.pageSettings.pageMobileRightSidebarFirstClicked = false;
    this.renderer.removeClass(document.body, 'bg-white');
  }

  // set page settings
  setPageSettings(settings) {
    for (const option in settings) {
      if (option) {
        this.pageSettings[option] = settings[option];
        if (option === 'pageBodyWhite' && settings[option] === true) {
          this.renderer.addClass(document.body, 'bg-white');
        }
      }
    }
  }

  // set page minified
  onToggleSidebarMinified(val: boolean): void {
    if (this.pageSettings.pageSidebarMinified) {
      this.pageSettings.pageSidebarMinified = false;
    } else {
      this.pageSettings.pageSidebarMinified = true;
    }
  }

  // set page right collapse
  onToggleSidebarRight(val: boolean): void {
    if (this.pageSettings.pageSidebarRightCollapsed) {
      this.pageSettings.pageSidebarRightCollapsed = false;
    } else {
      this.pageSettings.pageSidebarRightCollapsed = true;
    }
  }

  // hide mobile sidebar
  onHideMobileSidebar(val: boolean): void {
    if (this.pageSettings.pageMobileSidebarToggled) {
      if (this.pageSettings.pageMobileSidebarFirstClicked) {
        this.pageSettings.pageMobileSidebarFirstClicked = false;
      } else {
        this.pageSettings.pageMobileSidebarToggled = false;
      }
    }
  }

  // toggle mobile sidebar
  onToggleMobileSidebar(val: boolean): void {
    if (this.pageSettings.pageMobileSidebarToggled) {
      this.pageSettings.pageMobileSidebarToggled = false;
    } else {
      this.pageSettings.pageMobileSidebarToggled = true;
      this.pageSettings.pageMobileSidebarFirstClicked = true;
    }
  }


  // hide right mobile sidebar
  onHideMobileRightSidebar(val: boolean): void {
    if (this.pageSettings.pageMobileRightSidebarToggled) {
      if (this.pageSettings.pageMobileRightSidebarFirstClicked) {
        this.pageSettings.pageMobileRightSidebarFirstClicked = false;
      } else {
        this.pageSettings.pageMobileRightSidebarToggled = false;
      }
    }
  }

  // toggle right mobile sidebar
  onToggleMobileRightSidebar(val: boolean): void {
    if (this.pageSettings.pageMobileRightSidebarToggled) {
      this.pageSettings.pageMobileRightSidebarToggled = false;
    } else {
      this.pageSettings.pageMobileRightSidebarToggled = true;
      this.pageSettings.pageMobileRightSidebarFirstClicked = true;
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
    this.subscription.unsubscribe();
  }
  checkTime() {
    
    if (this.msalService.instance.getAllAccounts().length > 0) {
      this.authSvc.GetCurrentUser().subscribe(res => {
      })
      this.subscription = timer(0, 1000).
      subscribe(() => {
          this.expDate = Number(this.msalService.instance.getAccountByHomeId(this.msalService.instance.getAllAccounts()[0]['homeAccountId']).idTokenClaims.exp) || 0;
          this.currentDate = moment(new Date()).tz('America/Winnipeg').format('MM-DD-YYYY HH:mm:ss')
          this.currentDate = Number(Date.parse(this.currentDate.toLocaleString()).toString().substring(0, 10));
          if (this.msalService.instance.getAllAccounts().length <= 0 || this.currentDate >= this.expDate) {

            this.msalService.logoutRedirect();
            this.msalService.logout();
            // remove user from local storage to log user out
            localStorage.removeItem('currentUser');
            localStorage.removeItem('jwt');
            localStorage.removeItem('firstLoad');
            this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
          }
        }

        );
    }else{

      this.msalService.logoutRedirect();
      this.msalService.logout();
      // remove user from local storage to log user out
      localStorage.removeItem('currentUser');
      localStorage.removeItem('jwt');
      localStorage.removeItem('firstLoad');
      this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
    }
  }
  
  setFavIcon() {
    const favIcon: HTMLLinkElement = document.querySelector('#favIcon');
    favIcon.href = build_Configurations?.FavIcon || build_Configurations?.FavIconLocal;
  }

}
