import { Component, OnInit, ChangeDetectorRef, OnDestroy  } from '@angular/core';
import { TopBannerModel } from '../../../../models/marketing/topbanner.model';
import { ModalTransferModel } from '../../../../models/modal-transfer.model';
import { TopBannerTableModalComponent } from '../../../modals';
import { CommonService, InternationalizationService, AuthService, MarketingService } from '../../../../services';
import { BannerModel } from '../../../../models/marketing/banner.model';
import { ProductInfo } from '../../../../models/marketing/pruduct.info.model';
import {ClrDatagridSortOrder} from '@clr/angular';
import { Subscription } from 'rxjs';
import { LanguageItem } from '../../../../models/language-item.model';

@Component({
  selector: 'app-top-banner-table',
  templateUrl: './top-banner-table.component.html',
  styleUrls: ['./top-banner-table.component.scss']
})
export class TopBannerTableComponent implements OnInit, OnDestroy {
  tableState = 0;
  selectCount = 0;
  selectedSlider: TopBannerModel;

  data: Array<TopBannerModel> = [];
  dataFiltered: Array<TopBannerModel> = [];
  openTopBannerModal: boolean;
  modalReference: ModalTransferModel;
  ascSort = ClrDatagridSortOrder.ASC;
  isLoading:boolean = false;

  allVersions: ProductInfo[] = [];

  private subscibe$: Subscription = new Subscription();
  private subscibe2$: Subscription = new Subscription();
  
  constructor(private readonly srv: MarketingService,
    private readonly i18Svc: InternationalizationService,private readonly cdref: ChangeDetectorRef,
    public common: CommonService, public auth: AuthService) {
    this.i18Svc.initialize();
  }

  ngOnInit() {
    this.subscibe2$ = this.auth.languageChanges$.subscribe((language: LanguageItem) => {
      if(language) {
        this.get(false);
      }
    });
    if(this.auth.languageId) {
      this.get(false);
    }
  }

  ngDoCheck() {
    this.reloadLbl();
}
ngAfterViewInit(){
    this.reloadLbl();
}
reloadLbl(){
       
    this.cdref.detectChanges();
}


  public refresh() {
    this.selectedSlider = null;
    this.get(true);
  }

  public get(force: boolean) {
    this.tableState = 1;
    this.unsubscribeApi();
      this.subscibe$.add(this.srv.getMarketingSlider(force)
      .subscribe(data => this.onGetData(data)));
    }
    
    private onGetData(data) {
      if (data['result']) {
        this.unsubscribeApi();
      this.subscibe$.add(this.srv.GetAllVersionsAsOptions(this.auth.languageId)
      .subscribe((res) => {
        this.data = [];
        this.dataFiltered = [];
        const arrSlider: BannerModel[]= data['result'];
        const arrProdInfo: ProductInfo[] = res['result'];
        this.allVersions = res['result'] || [];
        arrSlider.forEach(x => {
          let obj={};
          const selectedProdInfo: ProductInfo = arrProdInfo.find((a: any) => a?.id === x?.versionId);
              obj = {...x,versionIdString:`${selectedProdInfo?.versionName || 'N/A'}(${x.versionId?x.versionId:'N/A'}) \n ${selectedProdInfo?.sku || 'N/A'}` }
          if (!this.common.isObjectEmpty(obj)) {
            this.data.push(TopBannerModel.parse(obj));
            this.dataFiltered.push(TopBannerModel.parse(obj));    
          }
      });
      }));
      this.tableState = 2;
    } else {
      this.tableState = 4;
    }
  }

  selectSlider(slider: TopBannerModel): void {
    this.selectedSlider = this.selectedSlider === slider ? null : slider;
  }

  sliderAdd() {
    this.openTopBannerModal = true;
    this.modalReference = new ModalTransferModel('new', TopBannerTableModalComponent, null, null, null);
  }

  sliderEditOrCopy(isEdit?: boolean) {
    if (this.selectedSlider?.id) {
      this.openTopBannerModal = true;
      this.modalReference = new ModalTransferModel(isEdit ? 'Edit': 'Copy', TopBannerTableModalComponent, this.selectedSlider, null, null);
    }
  }

  onTopBannerModalClose(refresh: boolean): void {
    this.openTopBannerModal = false;
    if (refresh) {
      this.refresh();
    }
  }

  sliderDisable(): void {
    this.isLoading = true;
    if (this.selectedSlider) {
      this.srv.disableSlider(this.selectedSlider.id).subscribe(sliderData => {
        if (sliderData.statusCode === 200) {
          this.isLoading = false;
          this.common.showSuccess(this.i18Svc.getTranslateVal('marketing_disable_info'));
          this.refresh();
        } else {
          this.isLoading = false;
          this.common.showError(`${this.i18Svc.getTranslateVal('error_text')}: ${sliderData.errorMessage}`);
        }
      });
    }
  }

  filter(filterValue: string) {
    filterValue = filterValue?.toUpperCase();
    this.dataFiltered = this.data.filter(x => x.id?.toString()?.toUpperCase().indexOf(filterValue) > -1
      || x.versionId?.toString()?.toUpperCase().indexOf(filterValue) > -1
      || x.header?.toUpperCase().indexOf(filterValue) > -1
      || x.subheader?.toUpperCase().indexOf(filterValue) > -1);
  }

  unsubscribeApi() {
    this.subscibe$?.unsubscribe();
    this.subscibe$ = new Subscription();
  }

  ngOnDestroy(): void {
    this.unsubscribeApi();
    this.subscibe2$?.unsubscribe();
  }

}
