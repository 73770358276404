import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalTransferModel } from '../../../models/modal-transfer.model';
import { UserDetailModel } from '../../../models/user/user-detail.model';
import { UserService } from '../../../services/user.service';
import { ListItemModel } from '../../../models/list-item.model';
import { AccountService } from '../../../services/account.service';
import { ResponseModel } from '../../../models/core/ResponseModel.model';
import { ConfigurationService } from '../../../services/configuration.service';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService, CommonService, InternationalizationService } from '../../../services';
import { ClrDatagridSortOrder } from '@clr/angular';
import { EmailService } from '../../../services/email.service';

@Component({
    selector: 'app-user-edit-modal',
    templateUrl: './user-edit-modal.component.html',
    styleUrls: ['./user-edit-modal.component.scss']
})
/** UserEditModal component*/
export class UserEditModalComponent implements OnInit {
    @Input() name: string;
    @Input() id: number;
    @Input() reference: ModalTransferModel;
    @Input() isTier0User: boolean;

    model: UserDetailModel;
    selectedNav = 1;
    regions: Array<ListItemModel> = [];
    dataFiltered: Array<ListItemModel> = [];
    dataSelected: Array<ListItemModel> = [];
    accounts: Array<ListItemModel> = [];
    dataSelectedAcc: Array<ListItemModel> = [];
    dataFilteredAcc: Array<ListItemModel> = [];
    accountsDataFiltered: Array<ListItemModel> = [];
    selectedAccounts: Array<ListItemModel> = [];
    roles: Array<ListItemModel> = [];
    filterRoles: Array<ListItemModel> = [];
    tierOptions = [];
    title: string;
    selectedRegionsIds: Array<number> = [];

    audits: Array<any> = [];
    languageList: Array<ListItemModel> = [];

    isRoleChanged: boolean;
    isRegionChanged: boolean;
    searchModel = '';
    @ViewChild('userForm') userForm: NgForm;

    accountState: number;
    accountsDisplayedColumns: string[] = ['select', 'id', 'value'];

    isEmailLoading: boolean;
    isEmailExist: boolean;
    firstloadRegions: boolean = true;
    firstloadAcc: boolean = true;
    @Input() set open(open: boolean) {
        this._open = open;
    }
    get open(): boolean {
        return this._open;
    }
    private _open: boolean;

    @Output() modalClose = new EventEmitter();


    loading: boolean;
    ascSort = ClrDatagridSortOrder.ASC;
    oldValue: number;

    constructor(private readonly usrService: UserService,
        private readonly actService: AccountService,
        private readonly configSvc: ConfigurationService,
        private readonly toastr: ToastrService,
        private readonly authSvc: AuthService,
        private readonly i18Svc: InternationalizationService,
        private readonly cdref: ChangeDetectorRef,
        private readonly emailService: EmailService,
        public common: CommonService) {
        this.i18Svc.initialize();
    }

    public canView = this.authSvc.canViewUsers;
    public canEdit = this.authSvc.canEditUsers;
    public tier = this.authSvc.getTier();

    public isNew = false;

    ngOnInit(): void {
        this.tierOptions.push({ value: 'Tier 3', id: 3 });
        this.tierOptions.push({ value: 'Tier 2', id: 2 });
        if (this.tier === 0 || this.tier === 1) this.tierOptions.push({ value: 'Tier 1', id: 1 });
        if (this.tier === 0) this.tierOptions.push({ value: 'Tier 0', id: 0 });

        this.getLanguageOptions();
        this.getAccount();
        this.isNew = this.reference.action === 'New';
        if (!this.isNew) {
            let title = this.canEdit ? 'Edit' : 'View';
            this.model = this.reference.object as UserDetailModel;
            this.title = `${title} ${this.model.Username}`;
            if (this.model.tier === 1) {
                this.getRegions();
            }
            this.tierSelectionChange();
        } else {
            this.model = this.reference.object as UserDetailModel;
            this.title = 'New User';
            this.model.languageId = this.authSvc.languageId;
            this.model.Accounts = [];
            if(this.model.tier !== 0) {
                this.model.Regions = [];
                const regionId = localStorage.getItem('current_region');
                try {
                this.model.Regions.push(new ListItemModel(Number(regionId), ''));
                } catch(_) {
                    this.model.Regions = [];
                }
            }
            this.tierSelectionChange();
        }
        if (this.reference.object.accountId) {
            this.model.accountId = this.reference.object.accountId;
            this.oldValue = this.model.accountId;
        }
    }

    ngAfterContentChecked() {
        this.cdref.detectChanges();
    }

    loadDefault(defaultRegion?: boolean) {
        if(defaultRegion) {
            this.loadDefaultRegions();
        }
        this.loadDefaultAccount();
    }

    loadDefaultAccount() {
        if (this.model.Accounts?.length > 0 && this.accounts.length > 0) {
            this.dataSelectedAcc = this.accounts.filter(acc => this.model.Accounts.some(res => acc.id === res.id));
        }
    }

    loadDefaultRegions() {
        if (this.model.Regions?.length > 0 && this.regions.length > 0) {
            this.dataSelected = this.regions.filter(reg => this.model.Regions.some(res => reg.id === res.id));
            if(this.dataSelected && this.model?.tier !== 0) {
                this.onRegionSelectionChange();
            }
        }
    }

    getRoleGroups() {
        this.usrService.getRoleGroups().subscribe(
            data => this.onGetRoleGroups(data),
            err => this.onError(err));
    }

    getMyRoleGroups() {
        this.usrService.getMyGroupRoles(this.model['id']).subscribe(
            data => {
                this.model.RoleGroup = data.result;
                this.getRoleGroups();
            },
            err => this.onError(err));
    }

    getRegions() {
        this.actService.getAllRegionsAsListItem().subscribe(
            data => this.onGetAllRegions(data),
            err => this.onError(err));
    }
    getAccount() {
        this.selectedRegionsIds = [];
        this.accountState = 1;
        this.regions.forEach(x => this.addToListIfSelected(x));
        this.actService.getAccountsAsListItemForRegions(this.getRegionId()).subscribe(
            data => this.onGetAllAccount(data, true),
            err => this.onError(err));
    }
    changeTabToAccounts() {
        this.selectedNav = 3;
    }
    changeTabToTask() {
        this.selectedNav = 4;
    }
    changeTabToAlert() {
        this.selectedNav = 5;
    }
    changeTabToAudit() {
        this.selectedNav = 6;
    }
    addToListIfSelected(x: ListItemModel) {
        if (x.getSelected()) {
            this.selectedRegionsIds.push(x.id);
        }
    }
    onGetRoleGroups(data: ResponseModel<any>) {
        if (data.result) {
            this.roles = [];
            data.result.forEach(x => {
                const item = new ListItemModel(0, x);
                if (this.model.RoleGroup && this.model.RoleGroup.indexOf(x) > -1) {
                    item.setSelected(true);
                    this.roles.push(item);
                }
                else if (this.model.tier === 1 && item.value.includes('Internal-')) this.roles.push(item);
                else if (this.model.tier === 2 && item.value.includes('Reseller-')) this.roles.push(item);

            });
            this.roles.sort((n1, n2) => {
                if (n1.value > n2.value) {
                    return 1;
                }

                if (n1.value < n2.value) {
                    return -1;
                }
                return 0;
            });
        }
        this.setRoleDesc();
    }

    setRoleDesc() {
        console.log(this.roles);
        this.roles?.forEach(x => {
            const roleList = this.common.userRoleList.find(y => y.role === x.value)?.access;
            if(roleList) {
                let roleDesc = '';
                roleList.forEach(r => {
                    roleDesc += (this.i18Svc.getTranslateVal(r+'_text') || '') + '\n';
                });
                x['roleDesc'] = roleDesc || '';
            }
        });
        console.log(this.roles)
    }

    onGetRoles(data: ResponseModel<any>) {
        if (data.result) {
            this.roles = [];
            data.result.forEach(x => {
                const item = new ListItemModel(0, x);
                if (this.model.Roles && this.model.Roles.indexOf(x) > -1) {
                    item.setSelected(true);
                }
                this.roles.push(item);
            });
            this.roles.sort((n1, n2) => {
                if (n1.value > n2.value) {
                    return 1;
                }

                if (n1.value < n2.value) {
                    return -1;
                }
                return 0;
            });
        }
    }
    onGetAllRegions(data: ResponseModel<any>) {
        if (data.result) {
            this.regions = [];
            data.result.forEach(x => {
                const item = ListItemModel.Parse(x);
                if (this.model.Regions?.some(y => y.id === item.id)) {
                    item.setSelected(true);
                }
                this.regions.push(item);
            });
        }

        this.dataFiltered = this.regions;
        this.loadDefault(true);
    }
    onGetAllAccount(data, defaultRegion?: boolean) {
        if (data['result']) {
            this.accounts = [];
            this.accountsDataFiltered = [];
            this.dataFilteredAcc = [];
            data['result'].forEach(x => {
                const account = ListItemModel.Parse(x);
                if (this.model.Accounts) {
                    const isSelected = this.model.Accounts.findIndex(x => x.id === account.id) >= 0;
                    account.setSelected(isSelected);
                }
                this.accounts.push(account);
                this.accountsDataFiltered.push(account);
            });

            this.accountState = this.accounts.length > 0 ? 2 : 3;
            if(this.isNew && !this.model.accountId) {
                setTimeout(() => {
                    this.model.accountId = -1;
                }, 1);
            }
        } else {
            this.accountState = 4;
        }
        this.loadDefault(defaultRegion);
        this.dataFilteredAcc = this.accounts;
        this.dataFilteredAcc.sort(function (x, y) {
            if (x.value.toLocaleLowerCase() < y.value.toLocaleLowerCase()) return -1;
            if (x.value.toLocaleLowerCase() > y.value.toLocaleLowerCase()) return 1;
            return 0;
        })
        if (this.reference.object.accountId) {
            this.selectAccount();
        }
    }

    onError(err) {
        this.accountState = 4;
    }

    tierSelectionChange() {
        if (!this.isNew) this.getMyRoleGroups();
        else this.getRoleGroups();

        const tier = this.reference.object ? this.model['tier'] : this.model.tier;
        if (tier === 2 || tier === 3 || tier === 1) {
            this.getRegions();
        }
    }

    getLanguageOptions(): void {
        if (this.languageList.length === 0) {
            this.configSvc.getLanguageOptions().subscribe(data => {
                if (data['result']) {
                    this.languageList = [];
                    data['result'].forEach(x => this.languageList.push(ListItemModel.Parse(x)));
                }
            });
        }
    }

    // Modal General UI Controls
    validModel() {
        return false;
    }
    close(refresh?: boolean) {
        if (refresh || !this.userForm.dirty || confirm(this.i18Svc.getTranslateVal('userEdit_confirm_text'))) {
            this.open = false;
            this.modalClose.emit(refresh);
        }
    }
    updateOrCreateUser() {
        this.isRoleChanged = false;
        this.isRegionChanged = false;
        this.model.RoleGroup = this.roles.filter(x => x.getSelected()).map(x => x.value);
        this.model.Regions = this.dataSelected;
        this.model.Accounts = this.dataSelectedAcc;
        this.loading = true;
        if (this.reference.action === 'New') {
            this.emailService.sendPW()
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.value) {
                        this.model.SendEmailConfirmation = true;
                    } else {
                        this.model.SendEmailConfirmation = false;

                    }
                    this.usrService.createUser(this.model).subscribe(() => this.onSuccess(), err => this.handleErr(err));
                })
        } else {
            this.usrService.updateUser(this.model).subscribe(() => this.onSuccess(), err => this.handleErr(err));
        }
    }

    updateUserRole(): void {
        const roles = this.roles.filter(x => x.getSelected()).map(x => x.value);
        this.loading = true;
        this.usrService.updateUserRolesGroup(this.model['id'], roles).subscribe(() => this.updateOrCreateUser(), err => this.handleErr(err));
    }
    save(): void {

        if (this.userForm?.valid) {
            if (this.reference.action !== 'New' && this.isRoleChanged) {
                this.updateUserRole();
            }
            else {
                this.updateOrCreateUser();
            }
        }
    }

    onSuccess(): void {
        this.loading = false;
        this.toastr.success('User have been saved');
        this.close(true);
    }


    onRegionChange(index: number): void {
        if (this.authSvc.isDBAdminUser) {
            this.regions[index].toggleSelected();
            this.userForm.control.markAsDirty();
            this.isRegionChanged = true;
        }
    }

    onRoleChange(index: number): void {
        if (this.authSvc.isDBAdminUser) {
            this.roles[index].toggleSelected();
            this.userForm.control.markAsDirty();
            this.isRoleChanged = true;
        }
    }

    accountsFilter(filterValue: string) {
        filterValue = filterValue.trim().toLowerCase();
        this.accountsDataFiltered = this.accounts.filter(x => {
            return x.id?.toString()?.toLowerCase().indexOf(filterValue) >= 0
                || x.value?.toLowerCase().indexOf(filterValue) >= 0
        });
    }

    accountIdToggle(e, element) {
        e.preventDefault();
        if (element.id !== this.model.accountId) {
            element.toggleSelected();
        }
    }
    selectAccount() {

        let selectedIndex;
        this.accounts.forEach((x, i) => {
            x.setSelected(false);
            if (x.id === this.model.accountId) {
                selectedIndex = i;
                x.setSelected(true);
            }
        });
        this.accounts.unshift(this.accounts[selectedIndex]);
        this.accounts.splice(selectedIndex + 1, 1);
        this.accountsDataFiltered = JSON.parse(JSON.stringify(this.accounts));
        this.setDefault();
    }


    handleErr(err): void {
        this.loading = false;
        this.toastr.error('connection error');
    }

    checkEmail(): void {
        const emailControl = this.userForm.controls.Email;
        let err = emailControl.errors;
        if (emailControl.hasError('duplicate')) {
            delete err['duplicate'];
            if (Object.keys(err).length === 0) {
                err = null;
            }
            this.userForm.controls.Email.setErrors(err);
        }
        if (!err) {
            this.isEmailLoading = true;
            this.isEmailExist = false;
            this.usrService.isEmailExist(emailControl.value).subscribe(data => {
                this.isEmailLoading = false;
                if (data.result) {
                    err = {
                        duplicate: true
                    };
                    setTimeout(() => {
                        this.userForm.controls.Email.markAsDirty();
                        this.userForm.controls.Email.setErrors(err);
                    }, 1);
                }
            }, () => this.isEmailLoading = false);
        }
    }

    filter(filterValue: string) {
        filterValue = filterValue?.toUpperCase();
        this.dataFiltered = this.regions.filter(x => x.value?.toUpperCase().indexOf(filterValue) > -1
        );
    }
    filterAcc(filterValue: string) {
        filterValue = filterValue?.toUpperCase();
        this.dataFilteredAcc = this.accounts.filter(x => x.value?.toUpperCase().indexOf(filterValue) > -1
        );
    }
    setDefault() {
        this.dataSelectedAcc = this.dataSelectedAcc.filter(res => Number(res.id) !== Number(this.oldValue))
        const acc = this.accounts.filter(x => x.id === Number(this.model.accountId));
        if(acc?.length>0) {
            this.dataSelectedAcc.push(acc[0]);
        }
        this.oldValue = this.model.accountId;
    }

    onRegionSelectionChange() {
        if(this.model?.tier !== 0) {
            this.getAccount2();
        }
    }

    getAccount2() {
        this.accountState = 1;
        this.actService.getAccountsAsListItemForRegions(this.getRegionId()).subscribe(
            data => this.onGetAllAccount(data),
            err => this.onError(err));
    }

    getRegionId(): number[] {
        if(!this.dataSelected) {
            this.selectedRegionsIds = [];
            this.regions.forEach(x => this.addToListIfSelected(x));
        } else {
            this.selectedRegionsIds = this.dataSelected.map(x => x.id);
        }
        return this.selectedRegionsIds;
    }

}
