import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef  } from '@angular/core';
import { ProductVersionModel } from '../../../models/product/product-version.model';
import { ProductModel } from '../../../models/product/product.model';
import { AuthService, CommonService, ProductService } from '../../../services';
import { MarketingService } from '../../../services/marketing.service';

@Component({
  selector: 'app-version-detail-modal',
  templateUrl: './version-detail-modal.component.html',
  styleUrls: ['./version-detail-modal.component.scss']
})
export class VersionDetailModalComponent implements OnInit {
  products: ProductModel[] = [];
  productsLoading: boolean;
  versions: ProductVersionModel[] = [];
  versionsDataFiltered: ProductVersionModel[] = [];
  selectedVersion: ProductVersionModel;
  versionsTableState = 0;
  selectedProduct: ProductModel;
  selectedProductId: number;

  @Input() set open(open: boolean) {
    this._open = open;
  }
  get open(): boolean {
    return this._open;
  }
  private _open: boolean;

  @Output() modalClose = new EventEmitter();
   

  loading: boolean;

  @Input() isCopy: boolean;
  @Input() tenentId: string;
  isValid: boolean;

  constructor(
              private readonly productSvc: ProductService,
              private readonly srv: MarketingService,private readonly cdref: ChangeDetectorRef,
              public common: CommonService,
              private readonly auth: AuthService
              ) { }
    title = ''
    ngOnInit(): void {
      this.isValid = !this.isCopy || !!this.tenentId;
      this.getProducts();
  }
  ngDoCheck() {
    this.reloadLbl();
}
ngAfterViewInit(){
    this.reloadLbl();
}
reloadLbl(){
       
    this.cdref.detectChanges();
}

getTenantID() {
  if(this.isCopy) {
    return this.tenentId;
  }
  return '';
}

  getProducts() {
    if(this.isValid) {
    this.products = [];
      this.productsLoading = true;
      this.productSvc.getProductByChannel(0, this.getTenantID())
      .subscribe(data => this.onGetProducts(data), err => this.errorHandler(err));
    }
  }
  onGetProducts(data) {
    this.products = data['result'].map(x => ProductModel.parse(x));
    this.versions = [];
    this.versionsDataFiltered = [];
      this.productsLoading = false;
  }

  // General Functions
  errorHandler(err) {
    console.log(err);
  }

  getVersions(id: number) {
    if(this.isValid) {
    this.versionsTableState = 1;
    this.versions = [];
    this.versionsDataFiltered = [];
    this.productSvc.getVersionsForProduct(id, this.getTenantID())
      .subscribe(data => this.onGetVersions(data), err => this.errorHandler(err));
    }
  }

  onGetVersions(data) {
    if (data['result']) {
      this.versions = [];
      this.versionsDataFiltered = [];
      data['result'].forEach(x => {
        this.versions.push(ProductVersionModel.parse(x));
        this.versionsDataFiltered.push(ProductVersionModel.parse(x));
      });
      this.versionsTableState = 2;
    } else {
      this.versionsTableState = 4;
    }
  }
  versionFilter(filterValue: string) {
    filterValue = filterValue?.toUpperCase();
    this.versionsDataFiltered = this.versions.filter(x => x.productName?.toUpperCase().indexOf(filterValue) > -1
      || x.internalSku?.toUpperCase().indexOf(filterValue) > -1
      || x.purchaseSku?.toString()?.toUpperCase().indexOf(filterValue) > -1
      || x.cost?.toString()?.toUpperCase().indexOf(filterValue) > -1
      || x.tagsCount?.toString()?.toUpperCase().indexOf(filterValue) > -1
      || x.sortingOrder?.toString()?.toUpperCase().indexOf(filterValue) > -1);
  }

  compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

    refreshVersions(id:number) {
        this.getVersions(id);
  }

  save() {
    if(this.isValid) {
    this.loading = true;
    this.srv.GetAllVersionsAsOptions(this.auth.languageId, this.getTenantID())
    .subscribe(res => {
      this.loading = false;
        let obj={};
        for (let i = 0; i < res['result'].length; i++) {
          const element = res['result'][i];
          if(Number(element['id']) === Number(this.selectedVersion?.id) && Number(this.selectedVersion?.id) !== 0){
            obj = {...this.selectedVersion,versionIdString:`${element['versionName']}(${element['id']}) ${element['sku']}` }
            break;
          }
        }
        if (obj && (Object.keys(obj).length === 0)) {
          obj = {...this.selectedVersion,versionIdString:`${this.selectedVersion['id']}` }
        }
        this.open = false;
      this.modalClose.emit(obj);
  }, () => this.loading = false);
}
  }

  close() {
    this.open = false;
    this.modalClose.emit();
  }

}
