export class TopBannerModel {
  id: number;
  versionId: number;
  priority: number;
  backgroundUrl: string;
  productUrl: string;
  redirectUrl: string;
  regionId: number;
  header: string;
  subheader: string;
  languageId: number;
  validFrom: Date;
  validTo: Date;
  isActive: boolean;
  headerColor: string;
  subheaderColor: string;
  buyBtnColor: string;
  redirectBtnColor: string;
  versionIdString: string;
  directionConfig: number;

  constructor(id: number,
    versionId: number,
    priority: number,
    backgroundUrl: string,
    productUrl: string,
    redirectUrl: string,
    regionId: number,
    header: string,
    subheader: string,
    languageId: number,
    validFrom: Date,
    validTo: Date,
    isActive: boolean,
    headerColor: string,
    subheaderColor: string,
    buyBtnColor: string,
    redirectBtnColor: string,
    versionIdString: string,
    directionConfig: number,
    ) {
    this.id = id;
    this.versionId = versionId;
    this.priority = priority;
    this.backgroundUrl = backgroundUrl;
    this.productUrl = productUrl;
    this.redirectUrl = redirectUrl;
    this.header = header;
    this.subheader = subheader;
    this.languageId = languageId;
    this.validFrom = validFrom;
    this.validTo = validTo;
    this.isActive = isActive;
    this.regionId = regionId;
    this.headerColor = headerColor;
    this.subheaderColor = subheaderColor;
    this.buyBtnColor = buyBtnColor;
    this.redirectBtnColor = redirectBtnColor;
    this.versionIdString = versionIdString;
    this.directionConfig = directionConfig;
  }

  public static parse(data): TopBannerModel {
    return new TopBannerModel(
      data['id'],
      data['versionId'],
      data['priority'],
      data['backgroundUrl'],
      data['productUrl'],
      data['redirectUrl'],
      data['regionId'],
      data['header'],
      data['subheader'],
      data['languageId'],
      data['validFrom'],
      data['validTo'],
      data['isActive'],
      this.parseColor(data['headerColor']),
      this.parseColor(data['subheaderColor']),
      this.parseColor(data['buyBtnColor']),
      this.parseColor(data['redirectBtnColor']),
      data['versionIdString'],
      data['directionConfig'] || BannerDefault.directionConfig);
  }

  static parseColor(color: string): string {
    return color ? (!color.includes('#') ? `#${color}` : color) : BannerDefault.color;
  }

}

export enum BannerDefault {
  color = '#ffffff',
  directionConfig = 0
}