
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalTransferModel } from '../../../../models/modal-transfer.model';
import swal from 'sweetalert2';
import { AccountRegionModel } from '../../../../models/account.model';
import { AccountService } from '../../../../services/account.service';
import { PriceListModel } from '../../../../models/product/price-list/price-list.model';
import { CurrencyModel } from '../../../../models/billing/currency.model';
import { TaxGroupModel } from '../../../../models/billing/tax.model';
import { ConfigurationService } from '../../../../services/configuration.service';
import { ResponseModel } from '../../../../models/core/ResponseModel.model';
import { ProductService, AuthService, InternationalizationService } from '../../../../services';

@Component({
    selector: 'app-region-modal',
    templateUrl: './region-modal.component.html',
    styleUrls: ['./region-modal.component.scss']
})
/** CurrencyModal component*/
export class RegionModalComponent implements OnInit {
    @Input() reference: ModalTransferModel;

    model: AccountRegionModel;

    editMode: boolean;
    isNew: boolean;
    title: string;
    canRemoveCurrency = false;
    loading = false;

    priceListList: Array<PriceListModel> = [];
    currencyList: Array<CurrencyModel> = [];
    taxesList: Array<TaxGroupModel> = [];

    @Input() set open(open: boolean) {
        this._open = open;
    }
    get open(): boolean {
        return this._open;
    }
    private _open: boolean;

    @Output() modalClose = new EventEmitter();

    constructor(public activeModal: NgbActiveModal, private readonly srv: AccountService, private readonly confSrv: ConfigurationService,
        private readonly productSrv: ProductService, private readonly auth: AuthService, private readonly i18Svc: InternationalizationService) {
        this.i18Svc.initialize();
    }
    canView = this.auth.canViewRegion;
    canEdit = this.auth.canEditRegion;

    ngOnInit() {
        this.initMode();
    }

    initMode() {
        // Set Mode
        this.isNew = !this.reference?.object;
        this.editMode = this.isNew;

        // Set Model
        if (!this.isNew) {
            this.model = this.reference.object;
        } else {
            this.model = AccountRegionModel.create();
            this.model.tax = TaxGroupModel.create();
            this.model.currency = CurrencyModel.create();
            this.reference.object = this.model;
        }

        // Set Title
        if (this.isNew) {
            this.title = 'Add Region';
        } else {
            this.title = 'Region: ' + this.model.name;
        }
        if (!this.model.priceList) {
            this.model.priceList = new PriceListModel(null, null, null, null, null, null, null, null, null, null, null);
        }
        this.getPriceList();
        this.getCurrencyList();
        this.getTaxesList();
    }

    getPriceList() {
        this.productSrv.getAllPriceList().subscribe(data => this.onGetPriceList(data), error => this.onError(error))
    }
    onGetPriceList(response: ResponseModel<any>) {
        if (response.result) {
            this.priceListList = [];
            response.result.forEach(x => {
                const pl = PriceListModel.Parse(x);
                if (this.model.priceList && this.model.priceList.Id === pl.Id) {
                    this.model.priceList = pl;
                }
                this.priceListList.push(pl);
            });
        }
    }
    getCurrencyList() {
        this.confSrv.getCurrency().subscribe(data => this.onGetCurrencyList(data), error => this.onError(error));
    }

    onGetCurrencyList(response: ResponseModel<any>) {
        if (response.result) {
            this.currencyList = [];
            response.result.forEach(x => this.currencyList.push(CurrencyModel.parse(x)));
        }
    }
    getTaxesList() {
        this.confSrv.getTaxGroups().subscribe(data => this.onGetTaxesList(data), error => this.onError(error));
    }
    onGetTaxesList(response: ResponseModel<any>) {
        if (response.result) {
            this.taxesList = [];
            response.result.forEach(x => this.taxesList.push(TaxGroupModel.parse(x)));
        }
    }

    onError(err) {

    }
    handleError(err) {
        console.log(' Error: ' + err);
        this.loading = false;
        swal(' Ops...', ' Something went wrong', 'error');
    }

    validModel(): boolean {
        return true;
    }
    cancel() {
        this.editMode = false;
    }
    goEditMode() {
        this.editMode = true;
    }

    save() {
        if (this.validModel() && this.canEdit) {
            this.loading = true;
            if (this.isNew) {
                this.model.priceList = null;
                this.model.currency = null;
                this.model.tax = null;
                this.srv.regionCreate(this.model)
                    .subscribe(data => this.onResponse(data),
                        err => this.handleError(err));
            } else {
                this.model.priceList = null;
                this.model.currency = null;
                this.model.tax = null;
                this.srv.regionUpdate(this.model)
                    .subscribe(data => this.onResponse(data),
                        err => this.handleError(err));
            }
        }
    }
    onResponse(data) {
        if (!this.reference) {
            this.reference = new ModalTransferModel('open', RegionModalComponent, null, null);
        }
        this.model = AccountRegionModel.parse(data['result']);
        this.reference.object = this.model;
        this.initMode();
        this.cancel();
        this.closeModal(true);
        this.loading = false;
    }
    close() {
        this.reference.close();
        this.closeModal();
    }

    closeModal(refresh?: boolean): void {
        this.open = false;
        this.modalClose.emit(refresh);
    }

}
